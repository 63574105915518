
import { Component, Prop, Vue } from 'vue-property-decorator';
import { EditableProperty } from '@/interfaces/components/cms/checklistFields/EditableProperty';
import { mapTranslationByKey } from '@/helpers/TranslationHelper';
import { UploadFile } from 'ant-design-vue/types/upload';
import { convertFileToAntdFile, uploadFile } from '@/helpers/CmsIndexHelper';
import { EntityConfiguration } from '@/interfaces/components/cms/EntityConfiguration';
import { LoadingOverlayHelper } from '@/helpers/LoadingOverlayHelper';
import { EntityInfo } from '@/interfaces/components/cms/EntityInfo';
import { AvailableProductSystemDetailsFields } from '@/interfaces/components/productSystemDetails/AvailableProductSystemDetailsFields';
import AutocompleteGroupInput from '@/components/global/AutocompleteGroupInput.vue';
import { EventBus } from '@/helpers/EventBusHelper';
import { EventBusEvents } from '@/enums/global/EventBusEvents';
import { Chrome } from 'vue-color';
import { EntitySegment } from '@/interfaces/components/cms/EntitySegment';
import { RouteNames } from '@/enums/routes/RouteNames';
import { CmsEntityTypes } from '@/enums/global/CmsEntityTypes';
import RichTextEditor from '@/components/views/CmsIndex/RichTextEditor.vue';
import MultipleImagesWrapper from '@/components/views/CmsIndex/MultipleImagesWrapper.vue';
import MultipleTextareas from '@/components/views/CmsIndex/MultipleTextareas.vue';
import CountriesDropdown from '@/components/global/CountriesDropdown.vue';
import { CustomFileRequest } from '@/interfaces/general/CustomFileRequest';
import SalesProcessForms from '@/components/views/CmsIndex/SalesProcessForms.vue';
import PaymentTypeRepository from '@/repositories/PaymentTypeRepository';
import { updateClientToDefaultPaymentTypeAndArchiveSelectedPaymentType } from '@/helpers/CmsIndex/PaymentTypeHelper';
import { mixins } from 'vue-class-component';
import { UserRightsMixin } from '@/mixins/UserRights';
import { CommonUserGroups } from '@/enums/global/CommonUserGroups';
import UserAliasItem from '@/components/views/CmsIndex/UserAliasItem.vue';
import UserAliases from '@/components/views/CmsIndex/UserAliases.vue';
import UserAliasConfig from '@/interfaces/components/cms/UserAliasConfig';
import WarrantyWarning from '@/models/WarrantyWarning';
import { fetchDropdownOptions } from '@/helpers/PriceManipulationHelper';

@Component({
    name: 'CmsIndexDetails',
    components: {
        UserAliases,
        UserAliasItem,
        SalesProcessForms,
        MultipleTextareas,
        AutocompleteGroupInput,
        'chrome-picker': Chrome,
        RichTextEditor,
        MultipleImagesWrapper,
        CountriesDropdown,
    },
})
export default class CmsIndexDetails extends mixins<UserRightsMixin>(UserRightsMixin) {
    @Prop({ default: '' }) private title!: string;
    @Prop({ required: true }) private entityId!: string | null;
    @Prop({ required: true }) private entity!: any | null;
    @Prop({ required: true }) private selectedLocale!: string | null;
    @Prop({ required: true }) private isEditing!: boolean;
    @Prop({ required: true }) private isCmsUserSuperAdmin!: boolean;
    @Prop({ required: true }) private entityInfo!: EntityInfo;
    @Prop({ required: true }) private entityConfig!: EntityConfiguration | null;

    private loadingOverlay = new LoadingOverlayHelper(this, {});
    private created() {
        EventBus.$on(EventBusEvents.cmsError, this.onReceiveErrors);
    }
    private get isExistingWarrantyWarning() {
        return this.$route.params.entityName === 'warranty-warnings' && this.$route.params.entityId !== 'new';
    }
    private get isNewWarrantyWarning() {
        return this.$route.params.entityName === 'warranty-warnings' && this.$route.params.entityId === 'new';
    }
    private get isUserElementDisabled() {
        if (
            this.$route.params.entityName !== CmsEntityTypes.Users &&
            this.$route.params.entityName !== CmsEntityTypes.UserGroupConnections
        ) {
            return false;
        }
        if (this.$route.params.entityName === CmsEntityTypes.UserGroupConnections) {
            return !this.canEditUserRights;
        }

        if (this.isEditing) {
            return !this.canEditUser;
        } else {
            return !this.canCreateUser;
        }
    }

    private fieldTranslation(editablePropertyName: string) {
        return mapTranslationByKey(editablePropertyName);
    }

    private isMultipleUploadField(editablePropertyType: string) {
        return editablePropertyType === 'gallery' || editablePropertyType === 'files';
    }

    private isFieldOfFilesType(editablePropertyType: string) {
        return editablePropertyType === 'files';
    }

    private isFieldOfExtendedFilesType(editablePropertyType: string) {
        return editablePropertyType === 'extendedFiles';
    }

    private async customRequest(
        { file, onSuccess, onError }: any,
        { editablePropertyName, fileType, nestedPropertyIndex, nestedPropertyName, imageType }: CustomFileRequest
    ) {
        this.loadingOverlay.start();
        let uploadedFile;
        let correctArrayToBePushedInto = this.entityInfo[editablePropertyName];
        const formData = new FormData();

        if (nestedPropertyIndex !== undefined) {
            if (nestedPropertyName !== undefined) {
                correctArrayToBePushedInto =
                    this.entityInfo[editablePropertyName][nestedPropertyIndex][nestedPropertyName];
            } else {
                correctArrayToBePushedInto = this.entityInfo[editablePropertyName][nestedPropertyIndex];
            }
        }
        try {
            uploadedFile = await uploadFile(file, fileType, imageType, nestedPropertyIndex, this.selectedLocale);
        } catch (e) {
            onError();
            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: (e as Error).message,
            });
            this.loadingOverlay.stop();
            return;
        }

        this.loadingOverlay.stop();
        if (fileType === 'multipleImages') {
            correctArrayToBePushedInto.splice(0, 1);
        }

        correctArrayToBePushedInto.push(convertFileToAntdFile(uploadedFile.data));

        this.emitDataChanges(true);
        onSuccess(formData);
    }

    private emitDataChanges(state: boolean) {
        EventBus.$emit(EventBusEvents.changesInDataMade, {
            state,
        });
    }

    private onHandleDelete() {
        if (this.$route.params.entityName === CmsEntityTypes.PaymentTypes && this.entityId) {
            const paymentType = PaymentTypeRepository.getById(this.entityId);
            if (paymentType && paymentType.isDefault) {
                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: this.$t('Nije moguće obrisati zadanu vrstu plaćanja') as string,
                });
                return;
            }
            this.$confirm({
                title: this.$t('Jeste li sigurni da želite obrisati ovu vrstu plaćanja?'),
                content: this.$t(
                    'Odabrana vrsta plaćanja bit će zamijenjena zadanom vrijednošću na svim klijentima te je više nećete moći odabrati'
                ),
                onOk: this.onOkDeletePaymentType,
            });
            return;
        }

        this.onDeleteEntity();
    }

    private async onOkDeletePaymentType() {
        if (this.entityId == null) {
            return;
        }

        try {
            await updateClientToDefaultPaymentTypeAndArchiveSelectedPaymentType(this.entityId);
        } catch (e) {
            return;
        }

        this.$notification.success({
            message: this.$t('Promjene uspješne!'),
            description: '',
        });
        this.emitDataChanges(false);

        this.loadingOverlay.stop();
        let currentRoute: string | string[] = this.$route.path.split('/');
        currentRoute.splice(3, 1);
        currentRoute = currentRoute.join('/');
        this.$router.push(currentRoute);
    }

    private onRemove(
        file: UploadFile,
        editablePropertyName: string,
        nestedPropertyIndex?: number,
        nestedPropertyName?: string
    ) {
        let correctArrayToBeSearched = this.entityInfo[editablePropertyName];
        if (nestedPropertyIndex !== undefined) {
            if (nestedPropertyName !== undefined) {
                correctArrayToBeSearched =
                    this.entityInfo[editablePropertyName][nestedPropertyIndex][nestedPropertyName];
            } else {
                correctArrayToBeSearched = this.entityInfo[editablePropertyName][nestedPropertyIndex];
            }
        }
        const removedFileIndex = correctArrayToBeSearched.findIndex((document: UploadFile) => {
            return document.uid === file.uid;
        });

        if (removedFileIndex === -1) {
            return;
        }

        correctArrayToBeSearched.splice(removedFileIndex, 1);

        this.emitDataChanges(true);
    }

    private async onRemoveNumberRangeRow(editableProperty: EditableProperty, numberRange: any, index: number) {
        this.entityInfo[editableProperty.name].splice(--index, 1);

        if (numberRange.id && this.entityConfig && this.entityConfig.nestedEntityDeleteEndpoint) {
            try {
                await this.entityConfig.nestedEntityDeleteEndpoint(numberRange.id);
            } catch (e) {
                let error;

                if (e instanceof Error) {
                    error = e.message;
                } else {
                    error = (e as { response: { data: { meta: { message: string } } } }).response.data.meta.message;
                }

                this.$notification.error({
                    message: this.$t('Dogodila se greška') as string,
                    description: error,
                });
                return;
            }

            this.$notification.success({
                message: this.$t('Poštanski brojevi su uspješno obrisani!') as string,
                description: '',
            });
        }
    }

    private onAddNewConfiguratorRow(editableProperty: EditableProperty) {
        if (editableProperty.nestedPropertyName == null || editableProperty.propertyLabelName == null) {
            return;
        }
        const emptyConfiguratorItem: AvailableProductSystemDetailsFields = {};
        emptyConfiguratorItem[editableProperty.nestedPropertyName] = [];
        emptyConfiguratorItem[editableProperty.propertyLabelName] = '';
        this.entityInfo[editableProperty.name].push(emptyConfiguratorItem);
    }

    private onUpdateUserAliases(userAliases: UserAliasConfig[]) {
        this.entityInfo.aliases = userAliases;
    }

    private onRemoveConfiguratorRow(editableProperty: EditableProperty, itemIndex: number) {
        this.entityInfo[editableProperty.name].splice(itemIndex, 1);
    }

    private async onSelectUpdate(id: string, editableProperty: EditableProperty) {
        if (editableProperty.getDropdownOptions == null) {
            return;
        }
        let selectedOption = editableProperty.getDropdownOptions().find((option: any) => {
            return option.id === id;
        });

        if (selectedOption === undefined) {
            this.entityInfo[editableProperty.name] = null;
            return;
        }

        this.entityInfo[editableProperty.name] = selectedOption;
    }

    private async onDropdownSearch(value: string, editableProperty: EditableProperty) {
        if (this.isNewWarrantyWarning) {
            WarrantyWarning.getAll({ validWarranty: true, filterQuery: value });
        }
    }

    private onDropdownValueSelect(id: string, editablePropertyName: string) {
        this.entityInfo[editablePropertyName] = id;
    }

    private onReceiveErrors(errors: Array<{ source: string; message: string }>) {
        const errorElements = document.querySelectorAll('*[data-name]');
        errorElements.forEach((errorElement: Element) => {
            errorElement.classList.remove('has-error');
        });

        errors.forEach((error: { source: string; message: string }) => {
            const newErrorElements = document.querySelectorAll('*[data-name="${error.source}"]');
            if (newErrorElements.length <= 0) {
                return;
            }
            newErrorElements.forEach((element: Element) => {
                element.classList.add('has-error');
            });
        });
    }

    private async onDeleteEntity() {
        this.$confirm({
            title: this.$t('Jeste li sigurni da želite obrisati ovaj podatak?'),
            onOk: async () => {
                this.loadingOverlay.start();
                if (this.entityId == null) {
                    return;
                }
                if (this.entityConfig == null || this.entityConfig.entityDeleteEndpoint == null) {
                    return;
                }

                try {
                    await this.entityConfig.entityDeleteEndpoint(this.entityId);
                } catch (e) {
                    const errorObj = e as any;
                    let errorMessage = errorObj.response ? errorObj.response.data.errors[0].detail : errorObj.message;
                    if (errorMessage === 'Cannot delete default payment type SI') {
                        errorMessage = this.$t('Nije moguće obrisati zadanu vrstu plaćanja');
                    }
                    if (errorObj.response.status === 500) {
                        errorMessage = this.$t('dupliciranaVrijednost') as string;
                    }
                    this.$notification.error({
                        message: this.$t('Dogodila se greška') as string,
                        description: errorMessage,
                    });
                    this.loadingOverlay.stop();
                    return;
                }
                this.$notification.success({
                    message: this.$t('Promjene uspješne!'),
                    description: '',
                });
                this.emitDataChanges(false);

                this.loadingOverlay.stop();
                let currentRoute: string | string[] = this.$route.path.split('/');
                currentRoute.splice(3, 1);
                currentRoute = currentRoute.join('/');
                this.$router.push(currentRoute);
            },
        });
    }

    private async onHandleDuplicate() {
        this.loadingOverlay.start();
        if (this.entityId == null) {
            return;
        }
        if (this.entityConfig == null || this.entityConfig.entityDuplicateEndpoint == null) {
            return;
        }

        try {
            await this.entityConfig.entityDuplicateEndpoint(this.entityId);
        } catch (e) {
            const errorObj = e as any;
            const errorMessage = errorObj.response ? errorObj.response.data.errors[0].detail : errorObj.message;

            this.$notification.error({
                message: this.$t('Dogodila se greška') as string,
                description: errorMessage,
            });
            this.loadingOverlay.stop();
            return;
        }
        this.$notification.success({
            message: this.$t('Promjene uspješne!'),
            description: '',
        });
        this.emitDataChanges(false);

        this.loadingOverlay.stop();
        let currentRoute: string | string[] = this.$route.path.split('/');
        currentRoute.splice(3, 1);
        currentRoute = currentRoute.join('/');
        this.$router.push(currentRoute);
    }

    private segmentIncludesUserGroup(segment: EntitySegment) {
        return segment.editablePropertiesConfiguration
            .map((editableProperty: EditableProperty) => editableProperty.type)
            .includes('userGroups');
    }

    private onUserGroupConnectionClick(userGroupConnectionId: string, userGroupConnectionKey: string) {
        this.$store.dispatch('temporaryCmsData/updateCmsUser', this.entityInfo);
        if (userGroupConnectionKey) {
            this.$router.push({
                name: RouteNames.cmsIndex,
                params: {
                    entityName: CmsEntityTypes.UserGroupConnections,
                    entityId: userGroupConnectionId || 'new',
                    userId: this.entityId as string,
                    userGroupConnectionKey,
                },
            });

            return;
        }
        this.$router.push({
            name: RouteNames.cmsIndex,
            params: {
                entityName: CmsEntityTypes.UserGroupConnections,
                entityId: userGroupConnectionId,
                userId: this.entityId as string,
                userGroupConnectionKey,
            },
        });
    }

    private checkAllChange(e: Event, segment: EntitySegment) {
        if (e.target == null) {
            return;
        }
        const selectedUserGroup = this.entityInfo.userGroup && this.entityInfo.userGroup.name;

        segment.editablePropertiesConfiguration.forEach((editableProperty: EditableProperty) => {
            if (
                editableProperty.isOnlyVisibleForRoltekGroup &&
                !Object.values(CommonUserGroups).includes(selectedUserGroup)
            ) {
                return;
            }
            // @ts-ignore
            this.entityInfo[editableProperty.name] = e.target.checked;
        });
        this.entityInfo[`indeterminate-${segment.indeterminateGroup}`] = false;
        // @ts-ignore
        this.entityInfo[`${segment.indeterminateGroup}-checkAll`] = e.target.checked;
    }

    private onCheckboxChange(segment: EntitySegment) {
        if (segment.indeterminateGroup == null) {
            return;
        }
        const checkedList = segment.editablePropertiesConfiguration.filter(
            (editableProperty: EditableProperty) => this.entityInfo[editableProperty.name]
        );
        const isIndeterminate =
            !!checkedList.length && checkedList.length < segment.editablePropertiesConfiguration.length;
        const isCheckedAll = checkedList.length === segment.editablePropertiesConfiguration.length;

        this.entityInfo[`indeterminate-${segment.indeterminateGroup}`] = isIndeterminate;
        this.entityInfo[`${segment.indeterminateGroup}-checkAll`] = isCheckedAll;
    }

    private formatDecimalNumbers(event: any, editablePropertyName: string) {
        if (event == null) {
            return;
        }

        this.entityInfo[editablePropertyName] = event.currentTarget.value.replace(',', '.');
    }

    private isElementVisibleBasedOnUserGroup(editableProperty: EditableProperty) {
        if (this.entityInfo.userGroup == null) {
            return true;
        }

        if (editableProperty.isOnlyVisibleForRoltekGroup) {
            if ((editableProperty.shouldCmsUserBeAdmin && !this.isCmsUserSuperAdmin) || !this.isSuperAdmin) {
                return false;
            }

            return Object.values(CommonUserGroups).includes(this.entityInfo.userGroup.name);
        }

        if (this.$route.params.entityName === CmsEntityTypes.UserGroupConnections && !this.isSuperAdmin) {
            return this.userRights.includes(editableProperty.name);
        }

        return true;
    }

    private beforeDestroy() {
        EventBus.$off(EventBusEvents.cmsError, this.onReceiveErrors);
    }

    private generatePassword(editablePropertyName: string) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        this.entityInfo[editablePropertyName] = result;
    }
}
